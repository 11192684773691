<template>
  <div>
    <div v-if="!$auth.loading">
      <!-- show login when not authenticated -->
      <button
        v-if="!$auth.isAuthenticated"
        @click="login"
      >
        Log in
      </button>
      <!-- show logout when authenticated -->
      <button
        v-if="$auth.isAuthenticated"
        @click="logout"
      >
        Log out
      </button>
    </div>

    <b-card
      v-if="$auth.isAuthenticated"
      title="Want to integrate JWT? 🔒"
    >
      <div>
        <div>
          <img :src="$auth.user.picture">
          <h2>{{ $auth.user.name }}</h2>
          <p>{{ $auth.user.email }}</p>
        </div>

        <div>
          <pre>{{ JSON.stringify($auth.user, null, 2) }}</pre>
        </div>
      </div>
    </b-card>
    <button @click="newToken">
      Test
    </button>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: { BCard },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect()
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
    async newToken() {
      const token = await this.$auth.getTokenSilently()
      return token
    },
  },
}
</script>

<style>

</style>
